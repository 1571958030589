import { __decorate } from "tslib";
import { Component, Vue, Watch } from 'vue-property-decorator';
import { OrgManageService } from '@/sevices';
import { H3List } from '@h3/thinking-ui';
import { namespace } from 'vuex-class';
import * as Utils from '@/common/utils';
const workPlatformModule = namespace('workPlatform');
let mWorkPlatFormHome = class mWorkPlatFormHome extends Vue {
    constructor() {
        super(...arguments);
        this.loading = false;
        this.todoLoad = false;
        this.todoLength = 0;
        this.allAppLoad = false;
        this.allAppLength = 0;
    }
    get appLoading() {
        // 只要还有一个模块在加载中就是加载中
        return this.todoLoad || this.allAppLoad;
    }
    showToast(show) {
        console.log(show);
        if (show) {
            this.$toast.show({
                icon: 'loading',
                text: '加载中',
            });
        }
        else {
            this.$toast.hide();
        }
    }
    // 切换成功组织后再去获取数据
    async onChangeIsSetOrg(v) {
        if (v) {
            await this.$nextTick();
            this.getTodoList().finally(() => {
                this.todoLoad = false;
            });
            this.getAllApp().finally(() => {
                this.allAppLoad = false;
            });
        }
    }
    async reload() {
        this.todoLoad = true;
        this.allAppLoad = true;
        this.loading = true;
        this.$refs.homeTodo.list = [];
        this.$refs.homeAllApp.page = 1;
        this.$refs.homeAllApp.onlineAppList = [];
        await this.getTodoList();
        this.todoLoad = false;
        await this.getAllApp();
        this.allAppLoad = false;
        this.loading = false;
    }
    created() {
        window.document.title = '首页';
    }
    async getAllApp() {
        const homeAllApp = await Utils.getComponentByRefs(this.$refs, 'homeAllApp');
        if (homeAllApp && !homeAllApp.onlineAppList.length) {
            await homeAllApp.nextPage();
        }
    }
    async getTodoList() {
        const homeTodo = await Utils.getComponentByRefs(this.$refs, 'homeTodo');
        if (homeTodo && !homeTodo.list.length) {
            await homeTodo.getTodoList();
        }
    }
    getListLength(val) {
        this.todoLength = val;
    }
    getAllAppLength(val) {
        this.allAppLength = val;
    }
    /**
     * user 存到缓存里
     */
    async getLoginInfo() {
        const res = await OrgManageService.getLoginInfo();
        if (res.success) {
            const data = res.data || {};
            const user = {
                userInfo: { id: data.id, name: data.name, username: data.username, type: 3 },
                departmentInfo: { id: data?.mainDepartmentId, name: data?.mainDepartmentName, type: 1 },
            };
            // 运行态的选人控件默认值需要用到
            localStorage.setItem('user', JSON.stringify(user));
        }
    }
    /**
     * 获取组织列表
     */
    async getOrgList() {
        const { data = [] } = await OrgManageService.getOrgList();
        if (data) {
            const curOrg = Array.isArray(data) &&
                data[0] &&
                data[0].id;
            if (curOrg) {
                this.setOrg(curOrg);
            }
        }
    }
    /**
     * 组织 存到缓存里
     */
    async setOrg(id) {
        const { success, data = {} } = await OrgManageService.setOrg({ orgId: id });
        if (success) {
            // 流程组需要用到
            localStorage.setItem('corpInfo', JSON.stringify(data));
            this.updateIsSetOrg(true);
            this.getLoginInfo();
        }
    }
    mounted() {
        this.todoLoad = true;
        this.allAppLoad = true;
        this.updateIsSetOrg(false);
        setTimeout(() => {
            this.getOrgList();
        }, 500);
    }
};
__decorate([
    workPlatformModule.Mutation('updateIsSetOrg')
], mWorkPlatFormHome.prototype, "updateIsSetOrg", void 0);
__decorate([
    workPlatformModule.State('isSetOrg')
], mWorkPlatFormHome.prototype, "isSetOrg", void 0);
__decorate([
    Watch('appLoading')
], mWorkPlatFormHome.prototype, "showToast", null);
__decorate([
    Watch('isSetOrg', { immediate: true })
], mWorkPlatFormHome.prototype, "onChangeIsSetOrg", null);
mWorkPlatFormHome = __decorate([
    Component({
        name: 'mWorkPlatFormHome',
        components: {
            H3List,
            homeTodo: () => import('./homeTodo.vue'),
            homeLatelyApp: () => import('./homeLatelyApp.vue'),
            homeAllApp: () => import('./homeAllApp.vue'),
        },
    })
], mWorkPlatFormHome);
export default mWorkPlatFormHome;
